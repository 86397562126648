import axiosConfig from '@/config/axios'
import axios from 'axios'

export default {

	login(one_login,one_senha,one_token_dev){
		return axios.get('https://backoffice.akmosplay.com.br/api/login/index',{
				params:{
					one_login,
					one_senha,
					one_token_dev
				}					      
			}			
		);
	}
}