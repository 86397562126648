import axios from 'axios'
import axiosConfig from '@/config/axios'

export default {

	saudacao(token){
		return axios.get('https://backoffice.akmosplay.com.br/api/usuario/token',{
			params:{
				token
			}
		});
	},
	
}