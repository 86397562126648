<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>  
  // import './assets/js/jquery-2.1.1.min.js'
  // import './assets/vendor/cicular_progress/circle-progress.min.js'
  // import './assets/vendor/sparklines/jquery.sparkline.min.js'
  // import './assets/js/adminux.js'
  // import './assets/js/chat/webflow.js'
  // import './assets/js/chat/scripts.js'
  
  export default {
    name: 'app',
    data () {
      return {
        msg: '',
        isMobile:false
      }
    },
    // created: function () {
    //   this.$http.interceptors.response.use(undefined, function (err) {
        
    //     return new Promise(function (resolve, reject) {
    //       if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
    //         this.$store.dispatch(logout)
    //       }
    //       throw err;
    //     });
    //   });
    // }
  }
</script>

<style type="text/css">
  @import './assets/css/chat/normalize.css';
  @import './assets/css/chat/webflow.css';
  @import './assets/css/chat/styles.css';
</style>