<template>
  	<div class="home">
	  	<h1>Eae men {{nome}}</h1>
	</div>
</template>

<script>
import HomeApi from '@/api/home-api'
import $ from 'jquery'

export default {
  name: 'home',
  data(){
  	return{
  		nome:''
  	}
  },
  methods:{

  },
  created(){
  	var self = this
  	HomeApi.saudacao().then((response)=>{ 	
			let data = response.data	 				
			self.nome = data
		},
	).catch(function (error) {
    
	    if(error.response.status===401){
	    	self.$store.dispatch('logout').then(() => {
				self.$router.push('/login')
			})
	    }else{
	    	console.log(error)
	    }
  	})
  },
  mounted(){
  	
  },
}
</script>
<style type="text/css">
	.novasmsg{
		color:#d30c0c;
		font-weight: 700;
	}
	.suporte_akmos{
		position: absolute;
    	width: 100%;
    	bottom: 0;
	}
</style>
