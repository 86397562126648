import Vue from 'vue'
import Container from '@/components/Container'
import Home from '@/components/Home.vue'

export default {
  path: '/',
  name: 'container',
  component: Container,
  children: [    
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: { 
        requiresAuth: true
      }
    },
  ]
}
