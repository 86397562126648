<template>
  <div class="container login">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <h5 class="card-title text-center">AKBANK</h5>
            <form class="form-signin" @submit.prevent="login">
              <div class="form-label-group">
                <input v-model="id_cadastro" type="text" id="inputId" class="form-control" placeholder="ID" required autofocus>
                <label for="inputId"></label>
              </div>

              <!-- <div class="form-label-group">
                <input v-model="senha" type="password" id="inputPassword" class="form-control" placeholder="Senha" required>
                <label for="inputPassword"></label>
              </div> -->
              <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Sign in</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container">
    <form class="form" @submit.prevent="login">
      <h2 class="form-signin-heading">Please sign in</h2>
      <label for="inputEmail" class="sr-only">Email address</label>
      <input required v-model="id_cadastro" type="text" placeholder="ID"/>
      <label for="inputPassword" class="sr-only">Password</label>
      <input required v-model="senha" type="password" placeholder="SENHA"/>
      <button class="btn btn-lg btn-primary btn-block" type="submit">Login</button>
    </form>
  </div> -->
</template>

<script>
  export default {
    data(){
      return {
        id_cadastro : "",
        senha : ""
      }
    },
    methods: {
      login: function () {
        let ak_login = this.id_cadastro 
        let ak_senha = this.senha

        this.$store.dispatch('login', { ak_login })
       .then(() => this.$router.push('/home'))
       .catch(err => console.log(err))
      }
    },
    mounted(){
      // alert(this.$route.query.token)
      alert(localStorage.getItem('token'))
    }
  }
</script>
<style type="text/css">
  
  :root {
    --input-padding-x: 1.5rem;
    --input-padding-y: .75rem;
  }

.container.login .row
{
  top: 25%;
  position: absolute;
  width: 100%;
}
.container.login {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  background: #9CECFB;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0052D4, #65C7F7, #9CECFB);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0052D4, #65C7F7, #9CECFB);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.card-signin {
  border: 0 !important;
  border-radius: 1rem !important;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1) !important;
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  border-radius: 2rem;
}

.form-label-group>input,
.form-label-group>label {
  padding: 5px 20px;
  font-weight: 400;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

</style>