import Vue 		from 'vue'
import App 		from './App.vue'
import router 	from './router'
import store 	from './store'
import './registerServiceWorker'
import Axios 	from 'axios'
import VeeValidate 	from 'vee-validate'
import VueSweetalert2 from 'vue-sweetalert2'
import BootstrapVue from 'bootstrap-vue'
import { library } 	from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCoffee,faUserCircle,faFolder,faShoppingCart,faAddressBook,faInfoCircle,faImage } from '@fortawesome/free-solid-svg-icons'

// import css
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

library.add(faCoffee,faUserCircle,faFolder,faShoppingCart,faAddressBook,faInfoCircle,faImage)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(VeeValidate)
Vue.use(VueSweetalert2)
Vue.use(require('vue-moment'));

Vue.config.productionTip = false

new Vue({
  router,
  store,
  // render: function (h) { return h(App) }
  render: h => h(App)
}).$mount('#app')
